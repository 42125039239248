import React from 'react'

import bootstrapService from '../../../../services/BootstrapService'

import dateImage from './images/calendar.svg'
import creditsImage from './images/clock.svg'
import deliveryImage from './images/delivery.svg'
import CourseInfo from '../../../shared/CourseInfo/CourseInfo'

import styles from './CourseCard.module.css'

class CourseCard extends React.Component {
    render() {
        const { title, description, imageUrl, releaseDate, credits, subjectArea, deliveryMethod, item, schedules } = this.props.course
        const showReleaseDate = bootstrapService.getShowReleaseDate() && releaseDate;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onMouseEnter={(e) => this.props.onMouseEnter(this.props.index)} onMouseLeave={(e) => this.props.onMouseLeave(this.props.index)}>
                <p className={styles['category']}>{subjectArea}</p>
                <h3 className={styles['title']}>{title}</h3>
                <p className={styles['description']}>{description}</p>
                <div className={styles['info']}>
                    <CourseInfo image={creditsImage} property={bootstrapService.getCreditsTerm(credits)} value={credits.toFixed(1)} />
                    {
                        showReleaseDate && releaseDate &&
                        <CourseInfo image={dateImage} property='Publish Date' value={new Date(releaseDate).toLocaleDateString("en-US", {month: 'short', year: 'numeric'})} />
                    }
                    {
                        !showReleaseDate &&
                        <CourseInfo image={deliveryImage} property='Delivery Method' value={deliveryMethod} />
                    }
                    {
                        !showReleaseDate && schedules && schedules[0] &&
                        <CourseInfo image={dateImage} property='Next Session' value={new Date(schedules[0].startDate).toLocaleDateString("en-US", {month: 'short', day: 'numeric'})} />
                    }
                </div>
                <img src={imageUrl} alt='' className={styles['image']} />
                <div className={styles['price']}>
                    {
                        item && item.price &&
                        <span className={styles['dollars']}>${String(item.price).split('.')[0]}</span>
                    }
                    {
                        item && item.price &&
                        <span className={styles['cents']}>{String(item.price).split('.')[1]}</span>
                    }
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseCard
    innerRef={ref} {...props}
/>);